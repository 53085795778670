import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from '@store/root';
import {
  ArtistStoreActions,
  DashboardStoreActions,
  DonorsStoreActions,
  EventStoreActions,
  EventStoreSelectors,
  OrganizerStoreActions,
  OrganizerStoreSelectors,
  TicketsStoreActions,
  TicketsStoreSelectors,
} from '@store/root/client';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { TicketResponse } from '@models/client/events/ticket-response.model';
import { map, take, tap } from 'rxjs/operators';

interface EventData {
  type: string;
  data: {
    entity?: string;
    entityId?: string;
    organizerId?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SseEventHandlerServiceService implements OnDestroy {
  ticketIds: string[] = [];
  private currentPage: number = 1;
  private selectFilter: string[];
  private urlPath = {
    homePage: '/',
    eventPage: '/event/',
    artistPage: '/profiles/public/artist/',
    organizerPage: '/profiles/public/organizer/',
    dashboard: '/dashboard',
    eventDashboard: '/dashboard/event/',
    boughtTickets: '/tickets/',
  };
  private unsubscribe = new Subject<void>();
  private subscriptions: Subscription[] = [];

  constructor(
    private location: Location,
    private store: Store<RootStoreState.State>,
  ) {}

  updateCurrentPage(page: number): void {
    this.currentPage = page;
  }

  updateSelectFilter(filter: string[]): void {
    this.selectFilter = filter;
  }

  handleEventData(eventData?: EventData) {
    const path = this.location.path().length > 0 ? this.location.path() : '/';
    const idUrl = this.getIdFromUrl(path);
    this.handleUrlPath(path, eventData, idUrl);
  }

  processCheckOutPath(id: string): void {
    this.store.dispatch(
      new TicketsStoreActions.GetOrganizerEventTickets({
        eventGUID: id,
        limit: '',
        page: '',
      }),
    );
  }

  getTicketById(id: string, tickets: TicketResponse[]): any | undefined {
    if (Array.isArray(tickets)) {
      return tickets.find((ticket) => ticket.id === id);
    }
    return undefined;
  }

  subscribeToTickets(
    ticketId: string,
    cartItemData: any[],
    callback: (data: any) => void,
  ): void {
    this.store
      .pipe(
        select(TicketsStoreSelectors.getOrganizerEventTicketsResponseState),
        takeUntil(this.unsubscribe),
      )
      .subscribe((data) => {
        if (data?.tickets && ticketId) {
          const totalTicket = this.getTicketById(ticketId, data?.tickets);
          const buyTicket = this.getTicketById(ticketId, cartItemData);
          const isTicketAvailable =
            totalTicket?.numberOfAvailable >= buyTicket?.count;
          callback({ totalTicket, buyTicket, isTicketAvailable });
        }
      });
  }

  getOrganizerId(): string | null {
    let organizerId: string | null = null;

    this.store
      .pipe(select(OrganizerStoreSelectors.getProfileIDState), take(1))
      .subscribe((id: string | null) => {
        organizerId = id;
      });

    return organizerId;
  }

  isTicketsChange(ticketsId: string): boolean {
    let isMatch = false;

    this.store
      .select(EventStoreSelectors.getTicketsDataState)
      .pipe(
        take(1),
        map((tickets) => {
          return tickets?.some((ticket) => ticket?.id === ticketsId);
        }),
      )
      .subscribe((match) => {
        isMatch = match;
      });

    return isMatch;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private handleUrlPath(path: string, data: EventData, idUrl: string): void {
    const { entityId, entity, organizerId } = data.data;
    const isMaineEventPage =
      idUrl === entityId ||
      this.loadEventId() === entityId ||
      this.isTicketsChange(entityId);
    this.loadTicketIds();
    if (isMaineEventPage) {
      if (path.startsWith(this.urlPath.eventPage)) {
        this.processEventPath(this.loadEventId());
      } else if (
        path.startsWith(this.urlPath.artistPage) &&
        entity === 'artistAccount'
      ) {
        this.processArtistPath(entityId);
      } else if (
        path.startsWith(this.urlPath.organizerPage) &&
        entity === 'organizerAccount'
      ) {
        this.processOrganizerPath(entityId);
      }
    } else if (
      path.startsWith(this.urlPath.dashboard) &&
      (this.getOrganizerId() === organizerId || this.isTicketIdExists(entityId))
    ) {
      this.dashboardOverview();
    } else if (
      path.startsWith(this.urlPath.boughtTickets) &&
      entity === 'event'
    ) {
      this.processBoughtTicketsPath(entityId);
    }
    if (
      path.startsWith(this.urlPath.eventDashboard) &&
      (this.getOrganizerId() === organizerId || this.isTicketIdExists(entityId))
    ) {
      this.dashboardEventOverview();
    }
  }

  private getIdFromUrl(urlPath: string) {
    let id = urlPath.match(/\/event\/([a-zA-Z0-9-]+)/);
    if (id) return id[1];

    id = urlPath.match(/\/profiles\/public\/artist\/([a-zA-Z0-9-]+)/);
    if (id) return id[1];

    id = urlPath.match(/\/profiles\/public\/organizer\/([a-zA-Z0-9-]+)/);
    if (id) return id[1];

    return null;
  }

  private processEventPath(id: string): void {
    this.store.dispatch(new EventStoreActions.GetEventById({ id }));
    this.store.dispatch(
      new DonorsStoreActions.GetDonorsByOrgAndSubId({
        eventId: id,
      }),
    );
  }

  private processArtistPath(id: string): void {
    this.store.dispatch(new ArtistStoreActions.GetProfileByID({ id }));
  }

  private processOrganizerPath(id: string): void {
    this.store.dispatch(new OrganizerStoreActions.GetProfileByID({ id }));
  }

  private dashboardOverview(): void {
    this.store.dispatch(new DashboardStoreActions.DashboardOverview());
    this.store.dispatch(
      new OrganizerStoreActions.GetOrganizersEvents({
        limit: 3,
        page: this.currentPage,
        statuses: this.selectFilter,
      }),
    );
    this.store.dispatch(new DashboardStoreActions.RecentOrder());
  }

  private dashboardEventOverview(): void {
    const eventId = this.loadEventId();
    if (eventId) {
      this.store.dispatch(
        new DashboardStoreActions.DashboardOverviewByEvent({
          eventId: eventId,
        }),
      );
      this.store.dispatch(
        new DashboardStoreActions.RecentOrderByEvent({
          eventId: eventId,
        }),
      );
    }
  }

  private processBoughtTicketsPath(eventId: string): void {
    const limit: any = null;
    const page: any = null;

    const payload = {
      eventId,
      limit,
      page,
    };
    this.store.dispatch(new TicketsStoreActions.GetFanBoughtTickets(payload));
  }

  private loadTicketIds(): void {
    this.store
      .pipe(
        select(OrganizerStoreSelectors.getOrganizersEventsResponseState),
        tap((eventsResponse) => {
          if (eventsResponse && eventsResponse.events) {
            this.ticketIds = this.extractTicketIds(eventsResponse.events);
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private loadEventId(): string {
    let eventId: string = '';
    const subscription = this.store
      .select(EventStoreSelectors.getEventIdState)
      .pipe(take(1))
      .subscribe((id) => {
        eventId = id || '';
      });

    this.subscriptions.push(subscription);
    return eventId;
  }

  private loadParentEventId(): string {
    let parentEventId: string = '';
    const subscription = this.store
      .select(EventStoreSelectors.getParentEventIdState)
      .pipe(take(1))
      .subscribe((id) => {
        parentEventId = id || '';
      });

    this.subscriptions.push(subscription);
    return parentEventId;
  }

  private extractTicketIds(events: any[]): string[] {
    const ticketIds: string[] = [];

    events.forEach((event) => {
      if (event.tickets && Array.isArray(event.tickets)) {
        event.tickets.forEach((ticket: any) => {
          if (ticket.id) {
            ticketIds.push(ticket.id);
          }
        });
      }
    });

    return ticketIds;
  }

  private isTicketIdExists(ticketId: string): boolean {
    return this.ticketIds.includes(ticketId);
  }
}
